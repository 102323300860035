import React, { useState, useEffect } from 'react';

import {
  Paper,
  Stack,
  FormGroup,
  TextField,
  Button,
  CircularProgress,
  Autocomplete,
  Fab,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions
} from '@mui/material';
import Grid from '@mui/material/Grid2';
import AddIcon from '@mui/icons-material/Add';

import useMediaQuery from '@mui/material/useMediaQuery';

import { DataFrame } from 'data-forge';
import moment from 'moment';

import { getMileage, recordMileage } from '../api/car';
import CarFuelEconomy from '../components/charts/CarFuelEconomy';
import CarFuelEconomyHistogram from '../components/charts/CarFuelEconomyHistogram';
import CarCostPerMilePlot from '../components/charts/CarCostPerMilePlot';
import CarDistance from '../components/charts/CarDistance';

function Car() {
  const [pageLoading, setPageLoading] = useState(true);
  const [formOpen, setFormOpen] = useState(false);

  const [registration] = useState('KS17GVP');
  const [garages, setGarages] = useState([]);

  const [litres, setLitres] = useState(null);
  const [miles, setMiles] = useState(null);
  const [cost, setCost] = useState(null);
  const [garage, setGarage] = useState(null);
  const [notes, setNotes] = useState(null);

  const [history, setHistory] = useState([]);

  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

  const submitMileage = async (data) => {
    const responseData = await recordMileage(data);
    console.log(responseData);
  };

  const getMileageHistory = async () => {
    const data = await getMileage({
      registration,
      start_date: new Date(2010, 1, 1),
      end_date: new Date()
    });

    const df = new DataFrame(data)
      .generateSeries({
        date: (row) => moment(row.filling_date).format('YYYY-MM-DD')
      })
      .orderBy((row) => row.date);

    setHistory(df);

    const garageCounts = df
      .groupBy((row) => row.petrol_station)
      .select((group) => ({
        name: group.first().petrol_station,
        count: group.count()
      }))
      .inflate()
      .toArray();

    const garageNames = garageCounts
      .toSorted((a, b) => b.count - a.count)
      .map((g) => ({ label: g.name }));

    setGarages(garageNames);
  };

  const handleSubmit = async () => {
    const record = {
      registration,
      litres,
      miles_driven: miles,
      price_per_litre: cost,
      petrol_station: garage,
      filling_date: new Date(),
      notes
    };

    console.log(record);
    await submitMileage(record);
    setPageLoading(true);
    await getMileageHistory(registration);
    setPageLoading(false);
    setFormOpen(false);
  };

  useEffect(async () => {
    await getMileageHistory(registration);
    setPageLoading(false);
  }, [registration]);

  const plots = [
    <CarFuelEconomy dataFrame={history} prefersDarkMode={prefersDarkMode} />,
    <CarFuelEconomyHistogram
      dataFrame={history}
      prefersDarkMode={prefersDarkMode}
    />,
    <CarDistance dataFrame={history} prefersDarkMode={prefersDarkMode} />,
    <CarCostPerMilePlot dataFrame={history} prefersDarkMode={prefersDarkMode} />
  ];

  return (
    <>
      <Grid
        container
        direction="row"
        alignContent="center"
        justifyContent="center"
        spacing={5}
        width="100%"
      >
        {plots.map((plot) => (
          <Grid key={plot.name} size={{ xs: 12, md: 6 }}>
            <Paper>
              {pageLoading ? <CircularProgress size={100} /> : plot}
            </Paper>
          </Grid>
        ))}
        <Fab
          color="primary"
          onClick={() => setFormOpen(true)}
          sx={{ position: 'fixed', bottom: '30px', right: '30px' }}
        >
          <AddIcon />
        </Fab>
      </Grid>

      <Dialog
        open={formOpen}
        onClose={() => setFormOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Mileage Input</DialogTitle>
        <DialogContent>
          <FormGroup>
            <Stack spacing={2}>
              <Autocomplete
                id="garage"
                freeSolo
                options={garages}
                getOptionLabel={(option) => option.label}
                renderInput={(params) => (
                  <TextField
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...params}
                    label="Garage"
                    margin="normal"
                    style={{ width: '100%', margin: 0 }}
                  />
                )}
                onChange={(event, reason) => {
                  if (reason && reason.label) {
                    const chosenGarage = reason.label;
                    setGarage(chosenGarage);
                  }
                }}
                onInputChange={(event, value) => {
                  if (value) setGarage(value);
                }}
              />
              <TextField
                id="litres"
                label="Litres Bought"
                type="number"
                required
                onChange={(event) => setLitres(parseFloat(event.target.value))}
              />
              <TextField
                id="price"
                label="Pence per Litre"
                type="number"
                required
                onChange={(event) => setCost(parseFloat(event.target.value))}
              />
              <TextField
                id="miles"
                label="Miles Driven"
                type="number"
                required
                onChange={(event) => setMiles(parseFloat(event.target.value))}
              />
              <TextField
                id="notes"
                label="Notes"
                onChange={(event) => setNotes(event.target.value)}
              />
            </Stack>
          </FormGroup>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setFormOpen(false)}>Cancel</Button>
          <Button variant="contained" color="primary" onClick={handleSubmit}>
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default Car;
