import chroma from 'chroma-js';

export const titleCase = (str) => {
  let upper = true;
  let newStr = '';

  for (let i = 0, l = str.length; i < l; i += 1) {
    // Note that you can also check for all kinds of spaces  with
    // str[i].match(/\s/)
    if (str[i] === ' ') {
      upper = true;
      newStr += str[i];
      // eslint-disable-next-line no-continue
      continue;
    }
    newStr += upper ? str[i].toUpperCase() : str[i].toLowerCase();
    upper = false;
  }

  return newStr;
};

// eslint-disable-next-line no-unused-vars
export const getColourScale = (df, metric) => {
  //   let metricMax = Math.max(...df.getSeries(metric).toArray());
  //   let metricMin = Math.min(...df.getSeries(metric).toArray());
  //   let metricRange = metricMax - metricMin;

  // ColorLover 11, diverging, Spectral:
  // https://plot.ly/python/v3/ipython-notebooks/color-scales/#colorlover

  const colours = [
    'rgb(158,1,66)',
    'rgb(213,62,79)',
    'rgb(244,109,67)',
    'rgb(253,174,97)',
    'rgb(254,224,139)',
    'rgb(255,255,191)',
    'rgb(230,245,152)',
    'rgb(171,221,164)',
    'rgb(102,194,165)',
    'rgb(50,136,189)',
    'rgb(94,79,162)'
  ].reverse();

  const colourScale = [];
  const n = 11;

  for (let i = 0; i < n; i += 1) {
    colourScale.push([i / (n - 1), colours[i]]);
  }

  return colourScale;
};

export const hexToMired = (hex) => parseInt(1e6 / chroma(hex).temperature());
export const hexToHSL = (hex) => chroma(hex).hsl();
export const hexToHSV = (hex) => chroma(hex).hsv();

export const hueSatBriToHex = (hue, sat, bri) =>
  chroma.hsv(hue, sat, bri).hex();
export const miredToKelvin = (mired) => 1e6 / mired;
export const kelvinToHex = (kelvin) => chroma.temperature(kelvin).hex();
export const miredToHex = (mired) => kelvinToHex(miredToKelvin(mired));

export const setupRegularUpdate = (minutes, method, params=[]) => {
  const methodName = method.name === "" ? '(anonymous method)' : method.name;
  console.log(`Setting up calls to ${methodName} every ${minutes} minutes`)

  const interval = setInterval(
    async () => {
      console.log(`Calling ${methodName}`);
      await method(...params);
    },
    minutes * 60 * 1000
  );

  return () => clearInterval(interval);
};
