import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import {
  Button,
  Paper,
  CircularProgress,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Typography
} from '@mui/material';
import Grid from '@mui/material/Grid2';

import useMediaQuery from '@mui/material/useMediaQuery';

import moment from 'moment';
import { formatDistance, parse } from 'date-fns';
import convert from 'convert';

import { getApiToken } from '../api/base';
import {
  userAuthorised,
  storeAuthInfo,
  getUserData,
  getDogs,
  getDogInfo,
  getActivityData,
  getPhoto
} from '../api/fitbark';

import FitbarkActivityMap from '../components/charts/FitbarkActivityMap';
import FitbarkActivityBars from '../components/charts/FitbarkActivityBars';
import FitbarkBatteryChart from '../components/charts/FitbarkBatteryChart';

import { setupRegularUpdate } from '../utils';

const processActivityData = async (dogId, combined = true) => {
  const reqData = {
    dog_id: dogId,
    start_date: moment().add(-90, 'days').format(),
    end_date: moment().format(),
    combined,
    from_source: false
  };

  try {
    const responseData = await getActivityData(reqData);
    return {
      responseData
    };
  } catch (err) {
    console.error(err);
    return {
      responseData: null
    };
  }
};

const getInfoData = async (dogId, fromSource = true) => {
  const reqData = {
    dog_id: dogId,
    from_source: fromSource
  };

  if (fromSource) {
    reqData.start_date = moment().add(-7, 'days').format();
  } else {
    // reqData.start_date = moment('2021-12-05').format();
    reqData.start_date = moment().add(-90, 'days').format();
    reqData.end_date = moment().add(-1, 'days').format();
  }
  try {
    const responseData = await getDogInfo(reqData);

    return {
      fromSource,
      responseData
    };
  } catch (err) {
    console.error(err);
    return {
      fromSource,
      responseData: null
    };
  }
};

function FitBark({ location }) {
  const [loading, setLoading] = useState(true);
  const [authorised, setAuthorised] = useState(null);
  const [userInfo, setUserInfo] = useState(null);
  const [dogs, setDogs] = useState(null);
  // const [userPhoto, setUserPhoto] = useState(null);
  const [selectedDog, setSelectedDog] = useState(null);
  const [dogPhoto, setDogPhoto] = useState(null);
  const [activityData, setActivityData] = useState(null);
  // const [infoData, setInfoData] = useState(null);
  const [cachedInfoData, setCachedInfoData] = useState(null);

  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

  const storeAuth = async () => {
    const queryString = location.search;
    const params = new URLSearchParams(queryString);
    const reqData = {
      code: params.get('code'),
      scopes: params.get('scope'),
      state: params.get('state')
    };
    const data = await storeAuthInfo(reqData);
    if (data.status && data.status === 'success') {
      const { protocol, hostname, port, pathname } = window.location;
      const redirectUrl = `${protocol}//${hostname}:${port}${pathname}`;
      console.log(redirectUrl);
      window.location.replace(redirectUrl);
    }
  };

  const checkAuthorised = async () => {
    try {
      const data = await userAuthorised();
      if (data.authorised === true) {
        setAuthorised(data.authorised);
      } else {
        console.error('User not authorised');
        setAuthorised(false);
      }
    } catch (err) {
      console.error(err);
      setAuthorised(false);
    }
  };

  const processUserData = async () => {
    try {
      const responseData = await getUserData();
      if (!responseData.error) setUserInfo(responseData);
    } catch (err) {
      console.error(err);
    }
  };

  const processDogs = async () => {
    try {
      const responseData = await getDogs();
      if (!responseData.error) setDogs(responseData);
    } catch (err) {
      console.error(err);
    }
  };

  const fetchPhoto = async (subjectType, slug) =>
    getPhoto({
      subject_type: subjectType,
      slug
    });

  if (location && location.search) storeAuth();

  useEffect(() => {
    if (loading) checkAuthorised();
  }, [loading]);

  useEffect(() => {
    if (authorised && !userInfo) {
      processUserData();
      processDogs();
    }
  }, [authorised, userInfo]);

  useEffect(() => {
    if (dogs?.dog_relations && !selectedDog) {
      setSelectedDog(dogs.dog_relations[0].dog);
    }
  }, [dogs, selectedDog]);

  useEffect(() => {
    if (selectedDog && !dogPhoto) {
      const getDogPhoto = async () => {
        const photoData = await fetchPhoto('dog', selectedDog.slug);
        setDogPhoto(photoData);
      };
      getDogPhoto();
    }
  }, [selectedDog, dogPhoto]);

  const updateDogData = async (dogId) => {
    try {
      const [activityResult, infoResult] = await Promise.all([
        processActivityData(dogId, true),
        getInfoData(dogId, false)
      ]);

      setActivityData(activityResult.responseData.activity_series.records);
      setCachedInfoData(infoResult.responseData);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  useEffect(async () => {
    if (selectedDog) {
      await updateDogData(selectedDog.slug);
    }
  }, [selectedDog]);

  useEffect(() => {
    if (selectedDog) {
      return setupRegularUpdate(15, updateDogData, [selectedDog.slug]);
    }
    return undefined;
  }, [selectedDog]);

  const progress = (height) => (
    <div
      style={{
        height,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center'
      }}
    >
      <CircularProgress size={100} />
    </div>
  );

  // const setupImage = (encodedString, desc) => {
  //   const imageSource = `data:image/jpeg;base64,${encodedString}`
  //   return <img
  //     src={imageSource}
  //     alt={desc}
  //     width={150}
  //     style={{
  //       borderRadius: '5%'
  //     }}
  //   />
  // };

  const convertWeight = (value, unit) => {
    if (unit === 'lbs')
      return `${convert(value, 'pounds').to('kg').toFixed(1)} kg`;
    return `${value} ${unit}`;
  };

  return (
    <Grid
      width="100%"
      container
      direction="row"
      alignContent="center"
      justifyContent="center"
      spacing={5}
    >
      {authorised === false && (
        <Grid size={{ xs: 12, md: 3 }}>
          <Paper>
            <Button
              variant="contained"
              onClick={async () => {
                const token = await getApiToken();
                const clientId =
                  '431aa24f6a8311bf9074326ecd442651f05d916760ab0beb0d07118bd1b4c92a';
                const redirectUri = `${window.location.href}`;
                const scope = 'partners';
                const responseType = 'code';
                const authUrl = `https://app.fitbark.com/oauth/authorize?response_type=${responseType}&client_id=${clientId}&redirect_uri=${redirectUri}&scope=${scope}&state=${token}`;
                window.location.replace(authUrl);
              }}
            >
              Login
            </Button>
          </Paper>
        </Grid>
      )}
      <Grid size={{ xs: 12, md: 5 }}>
        <Card>
          {dogPhoto ? (
            <CardActionArea style={{ display: 'flex' }}>
              <CardMedia
                component="img"
                alt={selectedDog.name}
                // height="160"
                image={`data:image/jpeg;base64,${dogPhoto.image.data}`}
                title={selectedDog.name}
                style={{ width: '50%' }}
              />
              <CardContent style={{ width: '50%' }}>
                <Typography gutterBottom variant="h5" component="h2">
                  {selectedDog.name}
                </Typography>
                <Typography variant="body2" color="textSecondary" component="p">
                  {`Age: ${formatDistance(
                    new Date(),
                    parse(selectedDog.birth, 'yyyy-MM-dd', new Date())
                  )} old`}
                </Typography>
                <Typography variant="body2" color="textSecondary" component="p">
                  {`Weight: ${convertWeight(
                    selectedDog.weight,
                    selectedDog.weight_unit
                  )}`}
                </Typography>
                <Typography variant="body2" color="textSecondary" component="p">
                  {`Battery: ${selectedDog.battery_level}%`}
                </Typography>
              </CardContent>
            </CardActionArea>
          ) : (
            progress('224.2px')
          )}
        </Card>
      </Grid>
      {authorised && (
        <>
          <Grid size={{ xs: 12, md: 8 }}>
            <Paper>
              {activityData === null ? (
                progress('456px')
              ) : (
                <FitbarkActivityMap
                  activityData={activityData}
                  prefersDarkMode={prefersDarkMode}
                />
              )}
            </Paper>
          </Grid>
          <Grid size={{ xs: 12, md: 8 }}>
            <Paper>
              {activityData === null ? (
                progress('456px')
              ) : (
                <FitbarkActivityBars
                  activityData={activityData}
                  prefersDarkMode={prefersDarkMode}
                />
              )}
            </Paper>
          </Grid>
          <Grid size={{ xs: 12, md: 8 }}>
            <Paper>
              {cachedInfoData === null ? (
                progress('456px')
              ) : (
                <FitbarkBatteryChart
                  data={cachedInfoData}
                  prefersDarkMode={prefersDarkMode}
                />
              )}
            </Paper>
          </Grid>
        </>
      )}
    </Grid>
  );
}

FitBark.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string
  }).isRequired
};

export default FitBark;
