import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import Plot from 'react-plotly.js';

import { format, parseISO } from 'date-fns';

import { Paper, CircularProgress } from '@mui/material';
import Grid from '@mui/material/Grid2';

import useMediaQuery from '@mui/material/useMediaQuery';

import PlotlyProgress from '../components/charts/PlotlyProgress';
import TogglBook from '../components/TogglBook';

import { getRecordedData, addHoliday } from '../api/toggl';
import { handlePlotlyDarkMode } from '../utils/themeUtils';
import { setupRegularUpdate } from '../utils';

const getYearAndWeekNumber = (dateIn) => {
  // Copy date so we don't modify original
  const date = new Date(
    Date.UTC(dateIn.getFullYear(), dateIn.getMonth(), dateIn.getDate())
  );
  // Set to nearest Thursday: current date + 4 - current day number
  // Make Sunday's day number 7
  date.setUTCDate(date.getUTCDate() + 4 - (date.getUTCDay() || 7));
  // Get first day of year
  const yearStart = new Date(Date.UTC(date.getUTCFullYear(), 0, 1));
  // Calculate full weeks to nearest Thursday
  const weekNo = Math.ceil(((date - yearStart) / 86400000 + 1) / 7);
  // Return array of year and week number
  return { year: date.getUTCFullYear(), week: weekNo };
};

const handleHoliday = async (holidayData) => {
  const date = new Date(holidayData.date);

  const postData = {
    year: date.getFullYear(),
    month: date.getMonth() + 1,
    date: date.getDate(),
    duration: holidayData.time,
    type: holidayData.holidayType
  };

  const responseData = await addHoliday(postData);
  console.log(responseData);
};

const getData = async (year, week) => {
  const { data, summary } = await getRecordedData(year, week);
  if (!data) return null;

  const processed = data
    .filter((item) => item['Week Number'] === week)
    .map((item) => {
      const originalDate = new Date(item.Date);
      return {
        ...item,
        Date: format(originalDate, "eee do'<br />'MMM"),
        DateSort: format(originalDate, 'yyyy-DDD')
      };
    });

  const projects = {};

  processed.forEach((item) => {
    if (item.Project in projects) {
      projects[item.Project].dates.push(item.Date);
      projects[item.Project].datesorts.push(item.DateSort);
      projects[item.Project].hours.push(item.Hours);
    } else {
      projects[item.Project] = {
        name: item.Project,
        colour: item.Colour,
        dates: [item.Date],
        datesorts: [item.DateSort],
        hours: [item.Hours]
      };
    }
  });

  return {
    data: Object.values(projects),
    summary
  };
};

function Toggl({ reqYear, reqWeek }) {
  const plotRef = useRef(null);
  const [plotWidth, setPlotWidth] = useState(null);

  const { year: thisYear, week: thisWeek } = getYearAndWeekNumber(new Date());
  const year = reqYear ?? thisYear;
  const week = reqWeek ?? thisWeek;

  const [data, setData] = useState(null);
  const [stats, setStats] = useState({});

  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

  const updateData = async () => {
    const { data: responseData, summary } = await getData(year, week);
    setData(responseData);
    setStats(summary);
  };

  useEffect(async () => updateData(), [year, week]);

  useEffect(() => setupRegularUpdate(5, updateData), []);

  useEffect(() => {
    if (plotRef && plotRef.current) {
      setPlotWidth(plotRef.current.getBoundingClientRect().width);
    }
  }, [setPlotWidth]);

  window.addEventListener('resize', () => {
    if (plotRef && plotRef.current) {
      setPlotWidth(plotRef.current.getBoundingClientRect().width);
    }
  });

  const spinner = (
    <div
      style={{
        height: '107px',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center'
      }}
    >
      <CircularProgress size={50} />
    </div>
  );

  const statsHaveDataRequired = (statsData, mainKey) => {
    if (!statsData) return false;
    if (!statsData[mainKey]) return false;
    if (statsData[mainKey].standard === null) return false;
    if (statsData[mainKey].contract === null) return false;
    if (statsData[mainKey].allocated === null) return false;
    return true;
  };

  const day = new Date().getDay();
  const isWeekday = day > 0 && day < 6;

  const sortByDates = (d, key) =>
    d?.[key]
      ? d[key].toSorted((a, b) => {
          const ia = d[key].indexOf(a);
          const ib = d[key].indexOf(b);
          return d.datesorts[ia] - d.datesorts[ib];
        })
      : null;

  return (
    <Grid
      container
      width="100%"
      direction="row"
      alignContent="center"
      spacing={5}
    >
      <Grid size={{ xs: 12, md: 6, lg: 4 }}>
        {statsHaveDataRequired(stats, 'today') ? (
          <PlotlyProgress
            text="Today"
            max={
              Math.max(stats.today.allocated * 1.05, stats.today.contract) || 1
            }
            current={stats.today.allocated}
            benchmark={stats.today.standard}
            target={stats.today.contract}
            deltaReference={stats.today.contract}
            range={1}
            subText={
              isWeekday
                ? `Est. finish ${format(parseISO(stats.today.estimatedFinish), 'HH:mm')}`
                : ''
            }
            darkMode={prefersDarkMode}
          />
        ) : (
          <Paper style={{ height: '135px' }}>{spinner}</Paper>
        )}
      </Grid>
      <Grid size={{ xs: 12, md: 6, lg: 4 }}>
        {statsHaveDataRequired(stats, 'week') ? (
          <PlotlyProgress
            text="Week"
            max={Math.max(stats.week.allocated * 1.05, stats.week.contract)}
            current={stats.week.allocated}
            benchmark={stats.week.standard}
            target={stats.week.contract}
            deltaReference={stats.week.contract}
            range={2}
            subText={
              isWeekday
                ? `Est. Friday finish ${format(
                    parseISO(stats.week.estimatedFinish),
                    'HH:mm'
                  )}`
                : ''
            }
            darkMode={prefersDarkMode}
          />
        ) : (
          <Paper style={{ height: '135px' }}>{spinner}</Paper>
        )}
      </Grid>
      <Grid size={{ xs: 12, md: 6, lg: 4 }}>
        {statsHaveDataRequired(stats, 'fortnight') ? (
          <PlotlyProgress
            text="Fortnight"
            max={Math.max(
              stats.fortnight.allocated * 1.05,
              stats.fortnight.contract
            )}
            current={stats.fortnight.allocated}
            benchmark={stats.fortnight.standard}
            target={stats.fortnight.contract}
            deltaReference={stats.fortnight.contract}
            range={2}
            subText={
              isWeekday
                ? `Est. Friday finish ${format(
                    parseISO(stats.fortnight.estimatedFinish),
                    'HH:mm'
                  )}`
                : ''
            }
            darkMode={prefersDarkMode}
          />
        ) : (
          <Paper style={{ height: '135px' }}>{spinner}</Paper>
        )}
      </Grid>
      <Grid size={{ xs: 12, md: 8 }}>
        <Paper>
          <div
            ref={plotRef}
            style={{
              height: '457px'
            }}
          >
            {data ? (
              <Plot
                data={data.map((project) => ({
                  x: sortByDates(project, 'dates'),
                  y: sortByDates(project, 'hours'),
                  type: 'bar',
                  name: project.name,
                  marker: {
                    color: project.colour
                  }
                }))}
                layout={handlePlotlyDarkMode(
                  {
                    width: plotWidth,
                    barmode: 'stack',
                    title: `${year} Week ${week}`,
                    xaxis: {
                      title: '',
                      range: [-0.5, 4.5],
                      categoryarray: sortByDates(
                        data.flatMap((p) => p.data),
                        'dates'
                      ),
                      categoryorder: 'array'
                    },
                    yaxis: {
                      title: 'Hours'
                    }
                  },
                  prefersDarkMode
                )}
                config={{
                  displayModeBar: false
                }}
              />
            ) : (
              spinner
            )}
          </div>
        </Paper>
      </Grid>
      <Grid size={{ xs: 12, md: 4 }}>
        <TogglBook
          onSubmit={(holidayData) => {
            handleHoliday(holidayData).then(() => {
              // ToDo: Reload more elegantly here...
              window.location.reload();
            });
          }}
          style={{
            height: '457px',
            padding: '20px'
          }}
        />
      </Grid>
    </Grid>
  );
}

Toggl.propTypes = {
  reqYear: PropTypes.number,
  reqWeek: PropTypes.number
};

Toggl.defaultProps = {
  reqYear: null,
  reqWeek: null
};

export default Toggl;
