/* eslint-disable react/forbid-prop-types */
import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';

import Plot from 'react-plotly.js';

import { handlePlotlyDarkMode } from '../../utils/themeUtils';

function PlotlyAdapter({ traces, config, layout, prefersDarkMode }) {
  const plotRef = useRef(null);
  const [plotWidth, setPlotWidth] = useState(null);

  useEffect(() => {
    if (plotRef?.current) {
      setPlotWidth(plotRef.current.getBoundingClientRect().width);
    }
  }, [setPlotWidth]);

  window.addEventListener('resize', () => {
    if (plotRef?.current) {
      setPlotWidth(plotRef.current.getBoundingClientRect().width);
    }
  });

  return (
    <div ref={plotRef}>
      <Plot
        data={traces}
        layout={handlePlotlyDarkMode(
          {
            ...layout,
            width: plotWidth
          },
          prefersDarkMode
        )}
        config={{
          ...config,
          displayModeBar: false
        }}
        useResizeHandler
      />
    </div>
  );
}

PlotlyAdapter.propTypes = {
  traces: PropTypes.arrayOf(PropTypes.any).isRequired,
  layout: PropTypes.any,
  config: PropTypes.any,
  prefersDarkMode: PropTypes.bool
};

PlotlyAdapter.defaultProps = {
  layout: {},
  config: {},
  prefersDarkMode: false
};

export default PlotlyAdapter;
